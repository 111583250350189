const prod = {
    url: {
     API_URL: process.env.REACT_APP_BOT_URL,
     AIRTABLE: process.env.REACT_APP_AIRTABLE,
     LIST_ID:process.env.REACT_APP_LIST_ID
    }
};

const dev = {
    url: {
     API_URL: `https://chatapi.ablejobs.co/app/connectedhchat`,
     AIRTABLE: `ConnectedH`,
     LIST_ID:`6,7`
    }
};


export const config = process.env.NODE_ENV === 'development' ? dev : prod;
