import React, {Component} from 'react';
import UserHeaderOptions from './UserHeaderOptions.js';

class HeaderComponent extends Component {

  render() {
    return (
      <div className="">
        <UserHeaderOptions active={this.props.active}/>
      </div>
    )
  }
}


export default HeaderComponent;
