import React, { Component } from 'react';
import './../ChatInput.css'

class ChatPanelComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      "selected_options":""
    }
  }

  handleChange = (li, state, field) => {
    this.props.getText(li, state, field)
    // console.log("state__"+state)
  }

  handleSelect = (e) => {
    var temp = this.state.selected_options
    if(temp.includes(e+',')) {
      temp = temp.replace(e+',','')
      console.log("delete")
    } else {
      temp += e+','
      console.log("add")
    }
    this.setState({selected_options:temp})
    console.log(this.state)
  }

  sendList = (state, interest) => {
    if(this.state.selected_options!==""){
      this.props.getText(this.state.selected_options, state, interest);
    }
  }

  setField = (field) => {
    this.props.setField(field)
  }

  notify=()=> {
    this.props.setNotify()
  }

  render() {
    return (
      <div id="chatMessages" className="" style={{top:'60px', bottom:'60px', position:'absolute', overflow:'auto', background:'#ece5dd', width:'100%'}}>
        {this.props.messageList.map(function (message, i) {
          if (message.type === 0) {
            if(message["field"]!==undefined&&message["field"]!=="non"&&i===message["description"].length-1){
              this.setField(message["field"])
            }
            if(message["field"]==="custom") {
              return (
                <div id={message.commentId} key={message.commentId} className={message["user"]===0?"user0":"user1"}>
                  <div className="">
                    <div className={message["user"]===0?"usertext0":"usertext1"} dangerouslySetInnerHTML={{ __html: message["description"] }} />
                    <div onClick={()=>{this.notify()}} style={{ background: '#c4c4c4', padding: '10px', marginBottom: '10px', borderRadius: '5px', textAlign:'center' }} >Notify me!</div>
                    <span className="time">
                      {message["time"]}
                    </span>
                  </div>
                </div>)
            }
            return (
              <div id={message.commentId} key={message.commentId} className={message["user"]===0?"user0":"user1"}>
                <div className="">
                  <div className={message["user"]===0?"usertext0":"usertext1"} dangerouslySetInnerHTML={{ __html: message["description"] }} />
                  <span className="time">
                    {message["time"]}
                  </span>
                </div>
              </div>)
          } else if (message.type === 1) {
            return (
              <div id={message.commentId} key={message.commentId} className={message["user"]===0?"user0":"user1"}>
                <div className="">
                  <div style={{ marginBottom: '10px', marginLeft:'10px', marginRight:'10px' }} dangerouslySetInnerHTML={{ __html: message["description"] }}></div>
                  {message["list"].map((li, i) => {
                    return (
                      <div  onClick={() => { this.handleChange(li, message["state"], message["field"]) }} style={{ background: '#c4c4c4', padding: '10px', marginBottom: '10px', borderRadius: '5px' }}>{li}</div>
                    )
                  })}
                  <span className="time">
                    {message["time"]}
                  </span>
                </div>
              </div>
            )
          }
          else if (message.type === 2) {
            return (
              <div id={message.commentId} key={message.commentId} className={message["user"]===0?"user0":"user1"}>
                <div className="">
                  <div style={{ marginBottom: '10px', marginLeft:'10px', marginRight:'10px' }} dangerouslySetInnerHTML={{ __html: message["description"] }}></div>
                  {message["list"].map((li, i) => {
                    return (
                      <div className="loading" onClick={() => { this.handleChange(li, message["state"], message["field"]) }} style={{ background: '#c4c4c4', marginBottom: '10px' }}><span className="text">{li}</span><span className="border"></span></div>
                    )
                  })}
                  <span className="time">
                    {message["time"]}
                  </span>
                </div>
              </div>
            )
          }
          
          else {
            var date = new Date()
            return (
              <div id={message.commentId} key={message.commentId} className={message["user"]===0?"user0":"user1"}>
                <div className="">
                  <div style={{ marginBottom: '10px', marginLeft:'10px', marginRight:'10px' }} dangerouslySetInnerHTML={{ __html: message["description"] }}></div>
                  {message["list"].map((li, i) => {
                    return (
                      <div>
                        <div style={{ padding:'2px',paddingLeft: '10px', background: '#f2f2f2', margin: '5px', paddingRight: '10px' }}>
                          <label for={li+date.getMilliseconds()} class="container"><p style={{fontSize:'15px', minHeight:'25px'}}>{li}</p>
                              <input className="checkbox" onChange={()=>{this.handleSelect(li)}} id={li+date.getMilliseconds()} type="checkbox"/>
                              <span  class="checkmark"></span>
                          </label>
                        </div >
                          {message["list"].length === i + 1 && <div onClick={()=>{this.sendList(message["state"],"interest")}} style={{ background: '#c4c4c4', padding: '10px', marginBottom: '10px', borderRadius: '5px', textAlign:'center' }} >Okay</div>}
                        </div>
                        )
                      })}
                      <span className="time">
                        {message["time"]}
                      </span>
                  </div>
              </div>
                )
              }

            }.bind(this))}
      </div>
            )
          }
        }


export default ChatPanelComponent;
