import React, {Component} from 'react';
import ChatMsgComponent from '../../Component/ChatMsgComponent/ChatMsgComponent.js';

class ChatMsgContainer extends Component {

  render() {
    return (
      <ChatMsgComponent enabled={this.props.enabled} setName={this.props.setName} updateFirebase={this.props.updateFirebase}  __chatMessages={this.props._chatMessages} />
    )
  }
}


export default ChatMsgContainer;
