import React, { Component } from 'react';
import MessageInputComponent from './MessageInputComponent.js'
import ChatPanelComponent from './ChatPannelComponent/ChatPanelComponent.js'
import { messaging, base } from '../../base'
import './ChatInput.css';
import axios from 'axios';
import SubscribeModalComponent from '../SubscribeModalComponent/SubscribeModalComponent';
import { config } from '../../Constants.js'
const $ = window.$;

class ChatMsgComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      "inputChatMessage": "",
      "state": "1",
      "disable_typing": "false",
      "flag": "0",
      "field": "",
      "name": "",
      "mobile": "",
      "open": false,
      "input_type":"text",
      "one_on_one_messages": this.props.__chatMessages
    }

    this._mapped_messaages = {};
    // this.activeChatId = this.props.__activeChatId;
    this.activeChatMessages = this.state.one_on_one_messages["chatMessages"]

    this.chatMessageHashMap = (userConversation) => {

      this._mapped_messaages = userConversation.reduce(function (map, obj, currentIndex) {
        map[obj.commentId] = currentIndex
        return map;
      }, {})
    }

    this.chatMessageHashMap(this.state["one_on_one_messages"]["chatMessages"]);

    //when you use arrow function, below binding is not required!!
    // this.changeInputChatMessage = this.changeInputChatMessage.bind(this)
  }


  _appendMessage = (e) => {
    if (e.key === "Enter" && this.state.inputChatMessage.length) {
      this.sendMessage();
    }
  }

  setNotify = () => {
    //if (('serviceWorker' in navigator) && ('PushManager' in window)) {
  
    if (messaging!=null) {
      this.suscribeToNotification(this.state.one_on_one_messages["contact"])
      console.log("supported")
    } else {
      console.log("not supported")
      $('#myModal').modal('show');
    //window.location.assign('https://tx.gl/r/1jy31');

    // window.location.assign('https://chat.whatsapp.com/GzofYNHEPCHEPuhWW1KrxV');

    }
  }

  //for option select
  getText = (e, state, field) => {
    this.updateState(e)
    this.getResponseMessage(e, state, field)
  }

  setField = (field) => {
    // console.log("setting field-"+field)
    if (this.state.field !== field) {
      this.setState({ field: field })
    }
  }

  // take the email from Modal and save to one_on_one_messages then send the data to firebase
  addEmailToFirebase = (email) => {
    var one_on_one_messages = this.state.one_on_one_messages;
    one_on_one_messages["email"] = email
    this.setState({one_on_one_messages})
    this.props.updateFirebase(this.state.one_on_one_messages)
  }

  getResponseMessage(e, state, field) {

    var message = {}
    //axios.post(`http://localhost:8000/app/connectedhchat`, {
    axios.post(config.url.API_URL, {
      ans: e,//text or selected option
      state: state,//increment stated sent from backend
      flag: this.state.flag//denote disqualification
    })
      .then(res => {
        message = res.data;
        var messagesCopy = { ...this.state.one_on_one_messages }
        messagesCopy["flag"] = this.state.flag === "1" ? "1" : message["flag"]
        messagesCopy["state"] = message["state"]
        // console.log("field__"+field+"  ans--" + e)
        var input = "text"
        switch (field) {
          case "fluent":
            messagesCopy["fluent"] = e
            console.log("setting fluency to - " + e)
            break
          case "graduated":
            messagesCopy["graduated"] = e
            console.log("setting graduated to - " + e)
            break
          case "location":
            messagesCopy["location"] = e
            console.log("setting location to - " + e)
            break
          case "interest":
            messagesCopy["interest"] = e
            console.log("setting interest to - " + e)
            break
          case "gender":
            messagesCopy["gender"] = e
            console.log("setting gender to - " + e)
            break
          case "experience":
            messagesCopy["experience"] = e
            console.log("experience -- "+e)
            break
          case "question1":
            messagesCopy["question1"] = e
            break
          case "question2":
            messagesCopy["question2"] = e
            break
          case "notify":
            console.log("notify --")
            break
        }
        
        if(message["field"] && message["field"] === "contact"){
          input = "number"
        }
        
        this.setState({ one_on_one_messages: messagesCopy, state: Number(message["state"]) + 1 + "", flag: "" + messagesCopy["flag"], field: message["field"], input_type:input })
        if (message["type"] === 1 || message["type"] === 2) {
          this.setState({ disable_typing: "true" })
        } else {
          this.setState({ disable_typing: "" })
        }
         //iterate all messages
        for (var k = 0; k < message["description"].length; k++) {

          var date = new Date();

          var hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
          var last = date.getHours() > 12 ? " PM" : " AM"
          var type = message["type"]
          if (type === 2) {
            if (k === message["description"].length - 1) {
              var typeset = 2
            } else {
              var typeset = 0
            }
          } else if (type === 1) {
            if (k === message["description"].length - 1) {
              var typeset = 1
            } else {
              var typeset = 0
            }
          } else {
            var typeset = 0
          }
          // typeset- normal text message=0 ,options type=1, checkbox type=2
          var msg = {
            "time": hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last,
            "description": message["description"][k],
            "commentId": this.state.one_on_one_messages["chatMessages"].length * 2,
            "user": 1,
            "type": typeset,
            "field": message["field"] ? message["field"] : "non",
            "state": this.state.state,
            "list": message["list"] ? message["list"] : "non"
          }

          let activeChat = Object.assign({}, this.state.one_on_one_messages);
          activeChat["chatMessages"].push(msg);

          this.setState(
            { one_on_one_messages: activeChat, inputChatMessage: "" },
            function () {
              this.chatMessageHashMap(this.state["one_on_one_messages"]["chatMessages"])
              var scrollToElement = document.getElementById("chatMessages");
              scrollToElement.scrollTo(1, scrollToElement.scrollHeight);
            })
          this.props.updateFirebase(this.state.one_on_one_messages)

        }

        if (message["field"] === "notify") {
          
          // log sucess event in Amplitude
          if(this.state.flag==="0") {
            console.log('User_Success_Entry_'+config.url.AIRTABLE, this.state.name,this.state.mobile)
            window.analytics.track('User_Success_Entry_'+config.url.AIRTABLE, {'name': this.state.name, 'mobile': this.state.mobile});
          }
          
          let activeChat = Object.assign({}, this.state.one_on_one_messages);
          activeChat["chatMessages"].push({
            "time": hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last,
            "description": 'Click the button below to get notificatins of new job openings<br>(No spams)🔔',
            "commentId": this.state.one_on_one_messages["chatMessages"].length * 2,
            "user": 1,
            "type": typeset,
            "field": "custom",
            "state": this.state.state,
            "list": "non"
          });
          this.setState(
            { one_on_one_messages: activeChat },
            function () {
              this.chatMessageHashMap(this.state["one_on_one_messages"]["chatMessages"])
              var scrollToElement = document.getElementById("chatMessages");
              scrollToElement.scrollTo(1, scrollToElement.scrollHeight);
            })
          this.props.updateFirebase(this.state.one_on_one_messages)
        }
        // if(field==="notify") {
        //   activeChat["chatMessages"].push({
        //     "time": hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last,
        //     "description": "example",
        //     "commentId": this.state.one_on_one_messages["chatMessages"].length * 2,
        //     "user": 1,
        //     "type": typeset,
        //     "field": "non",
        //     "state": this.state.state,
        //     "list": "non"
        //   });
        // }

      }).catch(error => {
        console.log(error)
      })
  }

  updateState(ans) {

    var date = new Date();

    var hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var last = date.getHours() > 12 ? " PM" : " AM"

    var message = {
      "time": hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last,
      "description": ans,
      "commentId": this.state.one_on_one_messages["chatMessages"].length * 2,
      "user": 0,
      "state": this.state.state,
      "type": 0
    }

    let activeChat = Object.assign({}, this.state.one_on_one_messages);
    activeChat["chatMessages"].push(message);

    this.setState(
      { one_on_one_messages: activeChat, inputChatMessage: "" },
      function () {
        this.chatMessageHashMap(this.state["one_on_one_messages"]["chatMessages"])
        var scrollToElement = document.getElementById("chatMessages");
        scrollToElement.scrollTo(1, scrollToElement.scrollHeight);
      })
    this.props.updateFirebase(this.state.one_on_one_messages)
  }

//for type messages
  sendMessage = () => {

    window.analytics.track('UserTyped_'+config.url.AIRTABLE);

    if (this.state.inputChatMessage !== "") {
      var date = new Date();

      var hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
      var last = date.getHours() > 12 ? " PM" : " AM"

      var message = {
        "time": hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last,
        "description": this.state.inputChatMessage,
        "commentId": this.state.one_on_one_messages["chatMessages"].length * 2,
        "user": 0,
        "state": this.state.state,
        "type": 0
      }
      let activeChat = Object.assign({}, this.state.one_on_one_messages);
      activeChat["chatMessages"].push(message);

      if (this.state.field === "name") {
        this.setState({name:this.state.inputChatMessage})
        console.log("setting name - " + this.state.inputChatMessage)
        window.analytics.track('User_Name_Entered_'+config.url.AIRTABLE, {'name': this.state.inputChatMessage});
        activeChat["name"] = this.state.inputChatMessage
      } else if (this.state.field === "contact") {
        this.setState({mobile:this.state.inputChatMessage})
        console.log("setting contact - " + this.state.inputChatMessage)
        window.analytics.track('User_PhoneNo_Entered_'+config.url.AIRTABLE, {'name': this.state.name, 'mobile': this.state.inputChatMessage});
        activeChat["contact"] = this.state.inputChatMessage
      }
      else if (this.state.field === "gender") {
      console.log("setting contact - " + this.state.inputChatMessage)
      activeChat["gender"] = this.state.inputChatMessage
     }

      this.getResponseMessage(this.state.inputChatMessage, this.state.state, "non")

      this.setState(
        { one_on_one_messages: activeChat, inputChatMessage: "" },
        function () {
          this.chatMessageHashMap(this.state["one_on_one_messages"]["chatMessages"])
          var scrollToElement = document.getElementById("chatMessages");
          scrollToElement.scrollTo(1, scrollToElement.scrollHeight);
        })
      this.props.updateFirebase(this.state.one_on_one_messages)
    }

  }

  _changeInputChatMessage = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  suscribeToNotification = (phone) => {
    messaging.requestPermission()
      .then(async function () {
        const token = await messaging.getToken()
          .then((currentToken) => {
            if (currentToken) {
              // sendTokenToServer(currentToken);
              // alert(currentToken);
              // console.log(currentToken);
              window.analytics.track('Push_Subscribed', {
                userid: phone,
              });
              let copy = this.state.one_on_one_messages
              copy["notify"] = "true"
              this.setState({ one_on_one_messages: copy })
              this.props.updateFirebase(this.state.one_on_one_messages)
              alert("You will be notified for new job openings!")
              axios.post('https://chatapi.ablejobs.co/app/insertdata', {
                contact: this.state.one_on_one_messages["contact"] ? this.state.one_on_one_messages["contact"] : "null",
                name: this.state.one_on_one_messages["name"] ? this.state.one_on_one_messages["name"] : "null",
                location: this.state.one_on_one_messages["location"] ? this.state.one_on_one_messages["location"] : "null",
                token: currentToken,
                interest: this.state.one_on_one_messages["interest"] ? this.state.one_on_one_messages["interest"] : "null",
                graduated: this.state.one_on_one_messages["graduated"] ? this.state.one_on_one_messages["graduated"] : "null",
                fluent: this.state.one_on_one_messages["fluent"] ? this.state.one_on_one_messages["fluent"] : "null"
              }).then(res => {
                console.log(res)
              }).catch(error => {
                console.log(error)
              })

            } else {
              // Show permission request.
              console.log('No Instance ID token available. Request permission to generate one.');
              // Show permission UI.
              //updateUIForPushPermissionRequired();
              //setTokenSentToServer(false);
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
            // showToken('Error retrieving Instance ID token. ', err);
            //setTokenSentToServer(false);
          })
      }.bind(this))
      .catch(function (err) {
        console.log("Unable to get permission to notify.", err);
      });
  }


  checkSubscription = () => {
      // Callback fired if Instance ID token is updated.
      messaging.onTokenRefresh(() => {
        messaging.getToken().then((refreshedToken) => {
          console.log('Token refreshed.');
          // Indicate that the new Instance ID token has not yet been sent to the
          // app server.
          //setTokenSentToServer(false);
          // Send Instance ID token to app server.
          //sendTokenToServer(refreshedToken);
          // ...
          
          axios.post('https://chatapi.ablejobs.co/app/insertdata', {
            contact: this.state.one_on_one_messages["contact"] ? this.state.one_on_one_messages["contact"] : "null",
            token: refreshedToken,
          }).then(res => {
            console.log(res)
          }).catch(error => {
            console.log(error)
          })
          console.log("Token updated");

        }).catch((err) => {
          console.log('Unable to retrieve refreshed token ', err);
          //showToken('Unable to retrieve refreshed token ', err);
        });
      });
  }


  componentDidMount() {
    this.setState({ disable_typing: this.props.enabled },
      function () {
        this.chatMessageHashMap(this.state["one_on_one_messages"]["chatMessages"])
        var scrollToElement = document.getElementById("chatMessages");
        scrollToElement.scrollTo(1, scrollToElement.scrollHeight);
      })

    if (('serviceWorker' in navigator) && ('PushManager' in window) && (this.state["one_on_one_messages"]['contact']!=null)) {
      this.checkSubscription()
    }
    
  }


  render() {

    return (
      <div>

        <ChatPanelComponent
          messageList={this.state.one_on_one_messages["chatMessages"]}
          getText={this.getText}
          setField={this.setField}
          setNotify={this.setNotify} />

        <MessageInputComponent
          disabled={this.state.disable_typing}
          appendMessage={this._appendMessage}
          changeInputChatMessage={this._changeInputChatMessage}
          inputChatMessage={this.state.inputChatMessage}
          sendMessage={this.sendMessage}
          input = {this.state.input_type} />

        <SubscribeModalComponent mobile={this.state.mobile} userName = {this.state.name} addEmailToFirebase={this.addEmailToFirebase}/>

      </div>
    )
  }
}


export default ChatMsgComponent;
