import React, {Component} from 'react';
import {Router, Route, Switch} from 'react-router';
import CombinedContainers from './Container/CombinedContainers';
import history from './history';

class Routes extends Component {

    render() {
        var date = Date.now()
        var url = window.location.href
        return(
            <div>
                <Router history={history}>
                    <div>
                        <Switch>
                         <Route exact path="/" component={CombinedContainers}/>
                         <Route exact path="/g" component={() => { window.location.href = url + date;return null; }}/>
                         <Route exact path="/t" component={() => { window.location.href = url + date;return null; }}/>
                         <Route exact path="/s" component={() => { window.location.href = url + date;return null; }}/>
                         <Route exact path="/e" component={() => { window.location.href = url + date;return null; }}/>
                         <Route exact path="/:slug" component={CombinedContainers} />
                        </Switch>
                    </div>
                </Router>
            </div>
        )

    }
}
export default Routes;
