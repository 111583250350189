import React, { Component } from 'react';
import HeaderContainer from './HeaderContainer/HeaderContainer.js';
import ChatMsgContainer from './ChatMsgContainer/ChatMsgContainer.js';
import { base, rootRef } from '../base';
import { config } from '../Constants.js'
class CombinedComponents extends Component {

  constructor(props) {
    super(props);
    this.state = {
      candidates: {},
      textInputEnabled:"",
      loading:true
    }
    this.sendMessage = () => {
      return this.state.candidates
    }

  }

  updateFirebase = (newValue) => {
    //var candidates = { ...this.state.candidates };
    var candidates = newValue
    this.setState({ candidates })
  }


  componentWillMount() {
    var contact = this.props.match.params.slug;
    if(contact==undefined){
      var date = Date.now();
      contact = 'f'+date;
    }
    var date = new Date();
    var hour = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var last = date.getHours() > 12 ? " PM" : " AM"
    /* rootRef.once("value")
      .then(function (snapshot) {
        var name = snapshot.child("name").val(); 
        console.log(name)
        if(!snapshot.hasChild(contact)){
          console.log("no")
          rootRef.child(contact).set({
              id: contact,
              chatMessages: [
                {
                  "time": hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last,
                  "description": "Hi!",
                  "commentId": 4,
                  "type": 0,
                  "user": 0
                },
                {
                  "commentId" : 2,
                  "description" : "Hi, Thanks for reaching out. <br> I will help you in getting a job. 🙋🏻‍♂️💼",
                  "field" : "non",
                  "list" : [ "Yes", "No" ],
                  "state" : "2",
                  "time" : hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last,
                  "type" : 0,
                  "user" : 1
                }, {
                  "commentId" : 4,
                  "description" : "Are you looking for a job right now?",
                  "field" : "non",
                  "list" : [ "Yes", "No" ],
                  "state" : "2",
                  "time" : hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last,
                  "type" : 1,
                  "user" : 1
                }]
            })
        } else {
          console.log("yes")
          // addCandidate(contact)
          // this.addCandidate(contact)
        }
      }); */
    // rootRef.child(contact).set({
    //   id: contact,
    //   chatMessages: [
    //     {
    //       "time": hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last,
    //       "description": "Hi!",
    //       "commentId": 4,
    //       "type": 0,
    //       "user": 0
    //     }]
    // })
    // console.log('AIRTABLE URL');
    // console.log(config);
    // console.log(process.env.NODE_ENV)
    // console.log(process.env.REACT_APP_AIRTABLE)
    // console.log(process.env.REACT_APP_BOT_URL)
    this.candidatesRef = base.syncState('candidates/' + contact, {
      context: this,
      state: 'candidates',
      then: (() => {
        console.log('STATE VALIDATION');
        console.log(this.state.candidates);
        if('id' in this.state.candidates){
          console.log("Already Present");
        }
        else{
          console.log("No");
          var candidates = {
            id: contact,
            airtable: config.url.AIRTABLE,
            chatMessages: [
              {
                "time": hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last,
                "description": "Hi!",
                "commentId": 4,
                "type": 0,
                "user": 0
              },
              {
                "commentId" : 2,
                "description" : "Hi, Thanks for reaching out. <br> I will help you in getting a job. 🙋🏻‍♂️💼",
                "field" : "non",
                "list" : [ "Yes", "No" ],
                "state" : "2",
                "time" : hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last,
                "type" : 0,
                "user" : 1
              }, {
                "commentId" : 4,
                "description" : "Are you looking for a job right now?",
                "field" : "non",
                "list" : [ "Yes", "No" ],
                "state" : "2",
                "time" : hour + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + last,
                "type" : 2,
                "user" : 1
              }
            ]
          };
          console.log("New candidate");
          console.log(candidates);
          this.setState({ candidates });
          this.setState({ loading:false });
        }
      })
    });

  }

  componentWillUnmount() {
    base.removeBinding(this.candidatesRef)
  }

  componentDidMount() {
    
    // this.addCandidate(contact)
  }

  render() {
    if(this.state.candidates["chatMessages"]){
      var last = this.state.candidates["chatMessages"].length-1
      if(this.state.textInputEnabled!=="true"&&this.state.candidates["chatMessages"][last]["type"]===0){
        this.setState({textInputEnabled:"true"})
      }
    }
    return (
      <div>
        {this.state.loading ? <div><HeaderContainer active={false} />
            <div style={{ paddingLeft: '20px', top:'60px', bottom:'60px', position:'absolute', overflow:'auto', fontSize:"20px", background:'#ece5dd', width:'100%', height:"calc(100%-60px)", paddingTop:"20px", position:'absolute', bottom:'0' }}> 
            <div> Searching jobs near you please wait.. 
            <i class="fa fa-circle-o-notch fa-spin" style={{fontSize:"24px"}}></i>
            </div>
            </div>
          </div>:
          <div className="">
            <div className="" style={{height:'100%'}}>
              <HeaderContainer active={false} />
              <ChatMsgContainer
                enabled={this.state.textInputEnabled}
                updateFirebase={this.updateFirebase}
                _chatMessages={this.sendMessage()} />
            </div>
          </div> 
        }
      </div>
    )
  }
}


export default CombinedComponents;
