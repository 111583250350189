import React, { Component } from 'react';
import Routes from './routes';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "timestamp": 'no timestamp yet'
    }

    // subscribeToTimer((err, timestamp) => this.setState({
    //   timestamp
    // }));
  }


  render() {
    return (
      <div className="">
        <Routes />
      </div>
    );
  }
}

export default App;
