import React, { Component } from 'react';
import './ChatInput.css'

class MessageInputComponent extends Component {

  render() {
    console.log("input type -- ",this.props.input)
    return (
      <div className="" style={{position:'absolute', height:'60px', overflow:'hidden', bottom:'0', background:'#eee', width:'100%'}}>


        <div className="" style={{display:'table', clear:'both', marginTop:'15px', width:'100%'}}>
          <div className="" style={{width:'80%', marginLeft:'5%', float:'left'}}>
            <input
              style={{width:'100%', borderRadius:'4px', border:'1px solid #c4c4c4', paddingLeft:'10px', height:'30px'}}
              className=""
              disabled={this.props.disabled}
              type={this.props.input}
              name="inputChatMessage"
              value={this.props.inputChatMessage}
              onKeyPress={this.props.appendMessage}
              onChange={this.props.changeInputChatMessage}
              placeholder="Type a message" />
          </div>
          <div className="text-center" style={{width:"10%", float:'left', marginLeft:'2.5%', marginTop:'2px'}}>
            <img onClick={this.props.sendMessage} src="images/enter.png" style={{width:'100%', maxWidth:'30px'}} />
          </div>
        </div>


      </div>
    )
  }
}


export default MessageInputComponent;
