import React, { Component } from 'react';
import SubscribeModalComponent from '../SubscribeModalComponent/SubscribeModalComponent';
const $ = window.$;

class UserHeaderOptions extends Component {

  handleClick = () => {
    // $('#myModal').modal('show');
  }
  
  render() {
    return (
      <div className="" style={{ background: '#eee', position: 'absolute', height: '60px', overflow: 'hidden', width: '100%' }}>
        <img alt="user_name" src="./images/applogo.png" width="38px" style={{ borderRadius: '76px', float: 'left', marginTop: '10px', marginLeft: '20px' }} />
        <p className="" style={{ marginTop: '18px', marginLeft: '70px', fontSize:'18px' }} href="#">Able Jobs</p>
        <div style={{ float: 'right', marginTop:'-30px', marginRight:'20px' }}>
            {this.props.active===true ?
                // <a data-toggle="modal" data-target="#myModal" className="">
                <a onClick={this.handleClick} className="">
                  <svg width="33" height="28" viewBox="0 0 73 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M53.0569 63.8805L47.5234 57.0992C46.9808 56.3458 46.6553 55.4846 46.6553 54.5159V40.5228C46.6553 31.8041 40.7963 24.0541 32.3332 21.686V16.1964C32.3332 13.1825 29.8377 10.8145 26.9082 10.8145C23.9787 10.8145 21.4832 13.2902 21.4832 16.1964V21.5784C12.9116 24.0541 7.05255 31.8041 7.16105 40.5228V54.5159C7.16105 55.4846 6.83555 56.3458 6.29305 57.0992L0.759505 63.8805C0.325502 64.4187 0 65.1722 0 65.9256V74.4291H54.0334V65.9256C53.8164 65.1722 53.4909 64.4187 53.0569 63.8805ZM24.6297 16.1964C24.6297 15.0124 25.6062 14.0436 26.7997 14.0436C27.9932 14.0436 28.9697 15.0124 28.9697 16.1964V21.0402C27.4507 20.8249 26.0402 20.8249 24.5212 21.0402V16.1964H24.6297ZM50.5614 71.0923H3.03802V65.9256L8.57156 59.1444C9.65657 57.8527 10.1991 56.2381 10.1991 54.5159V40.5228C10.1991 31.4811 17.5771 24.054 26.7997 24.054C36.0223 24.054 43.4003 31.3735 43.4003 40.5228V54.5159C43.4003 56.2381 43.9428 57.8527 45.0278 59.1444L50.5614 65.9256V71.0923Z" fill="#828689"/>
                  <path d="M24.6289 27.7129V30.9421C31.2475 30.9421 36.6725 36.324 36.6725 42.89H39.9275C39.9275 34.4942 33.092 27.7129 24.6289 27.7129Z" fill="#828689"/>
                  <path d="M26.7994 80.5621C23.6529 80.5621 21.1574 78.0864 21.1574 74.9648H17.9023C17.9023 79.8086 21.9169 83.7912 26.7994 83.7912C31.6819 83.7912 35.6965 79.8086 35.6965 74.9648H32.4415C32.4415 78.0864 29.9459 80.5621 26.7994 80.5621Z" fill="#828689"/>
                  <circle cx="50.0186" cy="22.9815" r="22.9815" fill="#FF2700"/>
                  <path d="M45.1914 19.75V17.2L49.8414 14.38H52.0614V34H49.7214V16.96L45.1914 19.75Z" fill="white"/>
                  </svg>
                </a>:
                <div></div>
            }
        </div>
      </div>
    )
  }
}


export default UserHeaderOptions;
