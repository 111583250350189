import Rebase from 're-base';
import firebase from 'firebase';



const config = {
    apiKey: "AIzaSyBgoDGivloAOfn_tiBCwJmnbGRS90JcEZY",
    authDomain: "rentingai-c1103.firebaseapp.com",
    databaseURL: "https://rentingai-c1103.firebaseio.com",
    projectId: "rentingai-c1103",
    storageBucket: "rentingai-c1103.appspot.com",
    messagingSenderId: "219236116538",
    appId: "1:219236116538:web:bda41587573d3fdf79ed03"
  };


const app = firebase.initializeApp(config)
const base = Rebase.createClass(app.database())
const rootRef = firebase.database().ref().child("candidates")
var messaging = null

if(firebase.messaging.isSupported()) {
  messaging = app.messaging();
  messaging.usePublicVapidKey(
    // Project Settings => Cloud Messaging => Web Push certificates
    "BB1UX7eDNAdzp2ohu9l5Sx2-3Q5LLYdZdQCAJaA-JDHLSp-OjQ6lmMVaXSTTxIpgr9EG-0b71k_SdmqGAeDOUL0"
  );
}

export { app,rootRef,messaging, base}
